import React, { Fragment, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "../../Complete/utils/bookingComplete.module.scss"
import { useBookingCompleteImages } from "../../Complete/hooks/useBookingCompleteImages"
import { ConsultationContext } from "../../ConsultationContext/ConsultationContext"

import { getSignedInUser } from "../../../Auth/services/user"
import { isBrowser } from "services/general"
import { GATSBY_MH_SUPPORT_EMAIL } from "gatsby-env-variables"

const Completed = (props) => {
  const { pageContext } = props
  const { module, content, nextPath } = pageContext
  const data = useBookingCompleteImages()
  const { consultationDispatch } = useContext(ConsultationContext)

  const { accessRule } = getSignedInUser()

  const homeUrl = accessRule?.homeUrl
  const supportEmail = accessRule?.supportEmail
    ? accessRule?.supportEmail
    : GATSBY_MH_SUPPORT_EMAIL
  useEffect(() => {
    const restartDetails = async () => {
      await consultationDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    }
    restartDetails()
  }, [consultationDispatch])

  const handleRedirect = () => {
    if (homeUrl) {
      if (isBrowser()) window.location.href = homeUrl
    } else navigate(nextPath)
  }

  return (
    <Layout
      seoTitle={module.seoTitle}
      isPrivate
      pageContext={pageContext}
      polygonStyle="completePages"
    >
      <Container isCentered>
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={8}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="mt-3 has-text-primary">{module.title}</h1>

          <div className="column">
            <Fragment>
              <p className="mb-2 header-size-text">
                Our mental health coordinator will contact you through SMS or
                email. If you don’t hear from us within the next working day, or
                should you wish to change some of your answers, please contact
                us at{" "}
                <a
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {supportEmail}
                </a>
              </p>
            </Fragment>
          </div>
          <center>
            {/* <Link to={nextPath} className="mt-1 button is-primary is-medium">
              {pageContext?.cta ? pageContext.cta : "Finish"}
            </Link> */}

            <Button color="primary" onClick={handleRedirect} size="medium">
              {pageContext?.cta ? pageContext.cta : "Finish"}
            </Button>
          </center>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
